import { createGlobalStyle } from 'styled-components';
export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    line-height: ${p => p.theme.typography.lineHeightBase};
    text-size-adjust: 100%;
  }

  body {
    font-weight: normal; /* stylelint-disable-line */
    ${p => p.theme.getFontStyle('primary', 'medium')}
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${p => p.theme.colors.background};
    color: ${p => p.theme.colors.textColor};
    
    font-size: ${p => p.theme.typography.textBase};
    word-wrap: break-word;
    margin: 0;
    padding: 0;
  }


  a {
    color: inherit;
    text-decoration: none;
  }
`;

export default GlobalStyle;
