import { ThemeProvider } from 'styled-components';
import { jackPreset } from '@shapegames/quick-draw-ui/lib/theme';
import '@shapegames/quick-draw-ui/lib/assets/icons/style.css';
import { AppProps } from 'next/app';
import GlobalStyle from '../components/GlobalStyle';
import FacebookPixel from 'src/components/FacebookPixel/FacebookPixel';
import NextNProgress from 'nextjs-progressbar';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ThemeProvider theme={jackPreset}>
      <GlobalStyle />
      <FacebookPixel />
      <NextNProgress />
      <Component {...pageProps} />
    </ThemeProvider>
  );
}

export default MyApp;
